import { Link } from 'gatsby'
import cn from 'classnames'

interface Props {
  text: string
  to: string
}

const MenuLink = ({ text, to }: Props) => {
  const className =
    'inline-block px-2 py-2 lg:px-6 lg:py-6 text-text hover:text-main text-lg no-underline font-Roboto text-light'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getProps = (props: any) => {
    if (!props.href || !props.location.pathname) {
      return { className }
    }
    const basePath = props.href.split('/')[1]
    const locationBasePath = props.location.pathname.split('/')[1]

    if (basePath === locationBasePath) {
      return { className: cn('!text-main', className) }
    }

    return { className }
  }

  return (
    <Link to={to} getProps={getProps}>
      {text}
    </Link>
  )
}

export default MenuLink
