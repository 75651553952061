import MenuLink from '../components/MenuLink'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import { RouteName, routes } from '../utils/routes'
import { PageProps } from 'gatsby'

const excludedRoutes = [RouteName.PHOTOS_GALLERY]

const Navigation = ({ uri }: Omit<PageProps, 'children'>) => {
  const isHomepage = uri === RouteName.HOME
  const getTitle = () => {
    if (!uri) return 'SDH Lipovka - Stránka nebyla nalezena'
    if (isHomepage) return 'SDH Lipovka - Domů'
    if (uri.includes(RouteName.NEWS)) return 'SDH Lipovka - Novinky'
    if (uri.includes(RouteName.CALENDAR)) return 'SDH Lipovka - Kalendář'
    if (uri.includes(RouteName.PHOTOS)) return 'SDH Lipovka - Fotky'
    if (uri.includes(RouteName.PHOTOS_CHILDREN))
      return 'SDH Lipovka - Fotky děti'
    if (uri.includes(RouteName.CALLS)) return 'SDH Lipovka - Výjezdy'

    return `SDH Lipovka - ${routes[uri]?.title ?? ''}`
  }
  return (
    <div>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <div className="container mx-auto px-4 w-auto text-center lg:text-left">
        <div className="lg:flex ">
          <div className="block lg:inline-block lg:w-auto w-16 mx-auto lg:m-0">
            <StaticImage
              src="../images/hasici_lipovka_logo.jpg"
              alt="Hasici Lipovka logo"
              height={70}
            />
          </div>
          {Object.keys(routes)
            .filter((route) => !excludedRoutes.includes(route as RouteName))
            .map((route) => (
              <MenuLink
                text={routes[route].title}
                to={routes[route].route}
                key={route}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default Navigation
