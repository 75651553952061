const Footer = () => {
  return (
    <div className="mt-8 lg:mt-16">
      <div className="container mx-auto px-4 py-3 w-auto text-16 text-light text-gray-400 text-center lg:text-left">
        &copy; {new Date().getFullYear()} SDH Lipovka
      </div>
    </div>
  )
}

export default Footer
