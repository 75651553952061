export enum RouteName {
  HOME = '/',
  NEWS = '/novinky',
  CALENDAR = '/kalendar',
  CALLS = '/vyjezdy',
  SDH = '/sdh',
  JSDH = '/jsdh',
  PHOTOS = '/fotky',
  PHOTOS_GALLERY = '/fotky/galerie',
  PHOTOS_CHILDREN = '/fotky-deti',
  CONTACT = '/kontakt',
}
export const getPhotosDynamicRoute = (page: number) =>
  `${RouteName.PHOTOS}/${page}`
export const getPhotosGalleryDynamicRoute = (id: string) =>
  `${RouteName.PHOTOS_GALLERY}/${id}`
export const getPhotosChildrenDynamicRoute = (page: number) =>
  `${RouteName.PHOTOS_CHILDREN}/${page}`
export const getNewsDynamicRoute = (page: number) => `${RouteName.NEWS}/${page}`
export const getEventsDynamicRoute = (page: number) =>
  `${RouteName.CALENDAR}/${page}`
export const getCallsDynamicRoute = (page: number) =>
  `${RouteName.CALLS}/${page}`

interface Route {
  title: string
  route: string
}

export const routes: Record<RouteName, Route> = {
  [RouteName.HOME]: {
    title: 'Domů',
    route: RouteName.HOME,
  },
  [RouteName.NEWS]: {
    title: 'Novinky',
    route: getNewsDynamicRoute(1),
  },
  [RouteName.CALENDAR]: {
    title: 'Kalendář',
    route: getEventsDynamicRoute(1),
  },
  [RouteName.CALLS]: {
    title: 'Výjezdy',
    route: getCallsDynamicRoute(1),
  },
  [RouteName.SDH]: {
    title: 'SDH',
    route: RouteName.SDH,
  },
  [RouteName.JSDH]: {
    title: 'JSDH',
    route: RouteName.JSDH,
  },
  [RouteName.PHOTOS]: {
    title: 'Fotky',
    route: getPhotosDynamicRoute(1),
  },
  [RouteName.PHOTOS_GALLERY]: {
    title: 'Fotky Galerie',
    route: RouteName.PHOTOS,
  },
  [RouteName.PHOTOS_CHILDREN]: {
    title: 'Fotky děti',
    route: getPhotosChildrenDynamicRoute(1),
  },
  [RouteName.CONTACT]: {
    title: 'Kontakt',
    route: RouteName.CONTACT,
  },
}
