import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { ReactNode } from 'react'
import { PageProps } from 'gatsby'

interface Props extends Omit<PageProps, 'children'> {
  children: ReactNode
}

const Screen = ({ children, ...rest }: Props) => {
  return (
    <div className="flex flex-col h-full">
      <Navigation {...rest} />
      <div className="flex-1">
        <div className="container mx-auto px-4 w-auto">{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Screen
